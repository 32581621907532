<template>
	<v-app>
		<div class="background"></div>
		<div class="foreground">
			<v-main>
				<slot />
			</v-main>
		</div>
	</v-app>
</template>

<style scoped>
.background {
	/* The image used */
	background: url("~/public/images/kiosk-background.png");

	/* Add the blur effect */
	filter: blur(2px);
	-webkit-filter: blur(2px);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	/* Full height */
	height: 100%;
	z-index: 1;
	transform: scale(1.1);
	position: fixed;
	left: 0;
	right: 0;
	display: block;
}

.v-main {
	padding-top: 0px !important;
	height: 100vh;
}

.foreground {
	height: 100%;
	position: relative;
	top: 0;
	left: 0;
	z-index: 2;
}
</style>
